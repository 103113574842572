.login {
    @apply flex flex-row items-center py-2 place-content-center;
}

    .login > div {
        @apply text-xs flex-col text-right px-2 md:w-96 sm:w-36 w-20 max-h-full text-nowrap;
    }

        .login > div > div:first-child {
            @apply text-ellipsis overflow-hidden;
        }

        .login > div > div:nth-child(2) {
            @apply font-bold uppercase;
        }

    .login > button {
        @apply text-xs sm:text-sm bg-button hover:bg-button-hover w-fit text-white text-nowrap p-2 h-min rounded-md disabled:opacity-50;
    }
