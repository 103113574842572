@import "./Components.css";

#home, #nochat-home {
    @apply container max-w-full mx-auto p-0 sm:p-4 rounded-lg;
}

    #home form {
        @apply grid grid-cols-1 md:grid-cols-2;
    }

    #home #message-panel {
        @apply bg-message-panel flex-1 p-4 md:p-8 md:rounded-bl-lg rounded-tl-lg rounded-tr-lg md:rounded-tr-none;
    }

    #home .desktop-message-panel {
        @apply min-h-full max-h-full overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300;
    }

    #home #form-panel {
        @apply p-4 md:p-8 min-h-full rounded-br-lg md:rounded-tr-lg rounded-bl-lg md:rounded-bl-none bg-form-panel;
    }


#nochat-home {
    @apply pt-0;
}

    #nochat-home form {
        @apply w-full h-full;
    }

    #nochat-home #message-panel {
        @apply bg-message-panel px-4 h-10 max-h-10 rounded-t-lg flex flex-row overflow-y-hidden overflow-x-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-track]:bg-gray-100  [&::-webkit-scrollbar-thumb]:bg-gray-300;
    }

    #nochat-home #form-panel {
        @apply p-8 h-full min-h-full rounded-b-lg bg-form-panel;
    }

    #nochat-home #form-panel #components {
        @apply grid md:grid-cols-2 xl:grid-cols-3;
    }

    #nochat-home #message-spacer {
        @apply block min-w-60;
    }
