.checkbox-input .row {
    @apply flex;
}

.checkbox-input .option {
    @apply flex items-center me-4;
}

    .checkbox-input .option label {
        @apply ml-2;
    }

.checkbox-input input {
    @apply size-4 text-input-text bg-gray-100 border-input-border rounded focus:ring-input-focus-border;
}
