#layout, #nochat-layout {
    @apply max-w-screen-lg min-w-96 mx-auto min-h-dvh flex flex-col;
}

#nochat-layout {
    @apply max-w-screen-xl;
}

header {
    @apply sticky top-0 z-50 bg-white shadow-[0_6px_4px_-4px_rgba(0,0,0,0.1)];
}

    header > div {
        @apply flex;
    }

#logo {
    @apply flex-none p-4;
}

    #logo img {
        @apply inline-flex w-[6rem];
    }

#login {
    @apply flex flex-1 place-content-end;
}

.loading-pulse {
    @apply border border-loading shadow rounded-2xl p-4 my-4 w-full mx-auto;
}

    .loading-pulse > div {
        @apply animate-pulse flex space-x-4;
    }

        .loading-pulse > div > div:first-child {
            @apply rounded-full bg-loading h-10 w-10;
        }

        .loading-pulse > div > div:nth-child(2) {
            @apply flex-1 space-y-6 py-1;
        }

            .loading-pulse > div > div:nth-child(2) > div:first-child {
                @apply h-2 bg-loading rounded;
            }

            .loading-pulse > div > div:nth-child(2) > div:nth-child(2) {
                @apply space-y-3;
            }

                .loading-pulse > div > div:nth-child(2) > div:nth-child(2) > div:first-child {
                    @apply grid grid-cols-3 gap-4;
                }

                    .loading-pulse > div > div:nth-child(2) > div:nth-child(2) > div:first-child > div {
                        @apply h-2 bg-loading rounded;
                    }

                        .loading-pulse > div > div:nth-child(2) > div:nth-child(2) > div:first-child > div:first-child {
                            @apply col-span-2;
                        }

                        .loading-pulse > div > div:nth-child(2) > div:nth-child(2) > div:first-child > div:nth-child(2) {
                            @apply col-span-1;
                        }

                .loading-pulse > div > div:nth-child(2) > div:nth-child(2) > div:nth-child(2) {
                    @apply h-2 bg-loading rounded;
                }

#nochat-home #message-panel .loading-pulse {
    @apply invisible;
}

#main {
    @apply flex-1 p-4 rounded-lg h-full bg-layout;
}

#requiresAuthentication {
    @apply flex-1 p-16 text-center text-white;
}

#footer {
    @apply p-2 text-center flex justify-center;
}

    #footer > span {
        @apply space-x-1.5 flex items-center;
    }

    #footer > img {
        @apply inline-flex w-20 h-5;
    }
