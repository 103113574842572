.radio-input .button-radio {
    @apply flex place-content-center;
}

    .radio-input .button-radio.column {
        @apply flex-col;
    }

    .radio-input .button-radio .option {
        @apply flex mx-2;
    }

    .radio-input .button-radio input {
        @apply size-0 border-none p-0 m-0;
    }

        .radio-input .button-radio input:checked + label {
            @apply bg-button;
        }


    .radio-input .button-radio .option label {
        @apply flex flex-col p-4 border p-1 rounded-md text-center hover:bg-button-hover;
    }



.radio-input .radio {
    @apply space-y-2;
}

    .radio-input .radio.row {
        @apply flex flex-row space-x-4 space-y-0 items-center;
    }

    .radio-input .radio .option {
        @apply space-x-2 space-y-4;
    }

    .radio-input .radio input {
        @apply border-input-border size-4;
    }

        .radio-input .radio input:checked {
            @apply border-solid border-8 border-input-focus-border;
        }


.radio-input .option label {
    @apply cursor-pointer;
}
