.table-container {
    @apply overflow-x-hidden;
}

.table-container > div > div > div {
    @apply flex flex-col -m-1.5 overflow-x-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300;
}

    .table-container > div > div > div > div > div {
        @apply p-1.5 min-w-full inline-block align-middle;
    }

        .table-container > div > div > div > div > div > div {
            @apply border rounded-lg divide-y divide-gray-light overflow-hidden;
        }

.table-container .search {
    @apply py-3 px-4;
}

    .table-container .search > div {
        @apply relative max-w-xs;
    }

        .table-container .search > div > label {
            @apply sr-only;
        }

        .table-container .search > div > input {
            @apply py-2 px-3 ps-9 block w-full border-input-border shadow-sm rounded-lg text-sm focus:z-10 focus:border-input-focus-border focus:ring-input-focus-border disabled:opacity-50 disabled:pointer-events-none;
        }

        .table-container .search > div > div {
            @apply absolute inset-y-0 start-0 flex items-center pointer-events-none ps-3;
        }

            .table-container .search > div > div > svg {
                @apply size-4 text-gray;
            }

.table-container table {
    @apply min-w-full divide-y divide-gray-light whitespace-nowrap;
}

    .table-container table thead {
        /*@apply bg-gray-light;*/
    }

    .table-container table th {
        @apply px-4 py-3 text-start text-xs font-medium tracking-wide text-gray-dark;
    }

        .table-container table th button {
            @apply inline-flex items-center gap-x-2;
        }

        .table-container table th svg {
            @apply flex-shrink-0 size-3.5 text-gray-dark;
        }

    .table-container table tbody {
        @apply divide-y divide-gray-light;
    }

        .table-container table tbody tr:hover {
            @apply hover:bg-gray-100;
        }

        .table-container table tbody td {
            @apply px-4 py-3 whitespace-nowrap text-sm text-gray-dark;
        }

            .table-container table tbody td input {
                @apply py-2 px-1 outline-none block w-full border border-input-border shadow-sm rounded-lg text-sm focus:z-10 focus:border-input-focus-border focus:ring-input-focus-border disabled:opacity-50 disabled:pointer-events-none;
            }

            .table-container table tbody td.action {
                @apply text-end;
            }

                .table-container table tbody td.action button {
                    @apply inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-action hover:text-action-hover disabled:opacity-50 disabled:pointer-events-none;
                }

            .table-container table tbody td.edit-menu > div {
                @apply px-6 py-2 relative;
            }

            .table-container table tbody td.edit-menu .hs-dropdown-menu {
                @apply opacity-100 -left-24;
            }

            .table-container table tbody td.edit-menu .hs-dropdown > button {
                @apply py-1.5 px-2 inline-flex gap-2 rounded-lg text-input-text disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-input-focus-border transition-all;
            }

            .table-container table tbody td.edit-menu button svg {
                @apply flex-shrink-0 size-4;
            }

            .table-container table tbody td.edit-menu > div > div {
                @apply [--placement:bottom-right] relative inline-block;
            }

                .table-container table tbody td.edit-menu > div > div > div {
                    @apply divide-gray-light min-w-40 z-20 bg-white shadow-2xl rounded-lg p-2 mt-2;
                }

                    .table-container table tbody td.edit-menu > div > div > div > div {
                        @apply py-2 first:pt-0 last:pb-0;
                    }

            .table-container table tbody td.edit-menu div.hs-dropdown > div button {
                @apply flex w-full items-center gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 focus:ring-2 focus:ring-input-focus-border;
            }

            .table-container table tbody td.edit-menu div.hs-dropdown .delete button {
                @apply text-red-500;
            }

        .table-container table tbody tr.new {
            @apply hover:bg-gray-light;
        }
