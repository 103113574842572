.input-container {
}

#nochat-home .input-container {
    @apply lg:mx-2;
}

    .input-container > div {
        @apply flex flex-wrap mb-6;
    }

        .input-container > div > div {
            @apply w-full px-3 mb-4 relative;
        }

    .input-container input, .input-container textarea {
        --tw-ring-shadow: inherit inherit inherit !important;
        border-color: inherit;
    }

.label {
    @apply block tracking-wide text-label text-xs font-bold mb-2;
}

.tooltip {
    @apply inline-block ml-1;
}

    .tooltip > button {
        @apply bg-tooltip-icon bg-no-repeat bg-cover size-5 inline-flex justify-center items-center gap-2 rounded-full outline-none;
    }

        .tooltip > button > span {
            @apply invisible opacity-0 transition-opacity inline-block absolute z-10 py-1 px-2 bg-gray-dark text-xs font-medium text-white rounded shadow-sm;
        }

        .tooltip.show > button > span {
            @apply visible opacity-100;
        }

p.error {
    @apply !text-red-500 text-sm;
}

.error {
    @apply !border-red-500;
}

.error-icon {
    @apply absolute inset-y-0 end-5 flex items-center pointer-events-none pe-3;
}

.error-icon svg {
    @apply shrink-0 size-4 text-red-500;
}

#nochat-home .nowrap {
    @apply md:col-start-1 md:col-end-3 xl:col-end-4;
}