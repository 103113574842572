.menu-top {
    @apply relative max-w-[85rem] w-full px-4 md:px-6 lg:px-8 text-nowrap;
}

    .menu-top .menu-toggle-1 {
        @apply relative sm:flex sm:items-center;
    }

    .menu-top .menu-toggle-2 {
        @apply flex items-center justify-between;
    }

    .menu-top .menu-toggle-3 {
        @apply sm:hidden;
    }

    .menu-top .hs-collapse {
        @apply overflow-hidden transition-all duration-300 basis-full grow sm:block;
    }

    .menu-top .menu-items {
        @apply flex flex-col sm:flex-row;
    }

.hs-dropdown {
    @apply p-2 mx-auto sm:mx-0 [--strategy:static] sm:[--strategy:absolute] [--adaptive:none] [--is-collapse:true] sm:[--is-collapse:false];
}

.hs-dropdown-toggle {
    @apply flex items-center w-full text-gray-dark font-medium hover:text-gray focus:outline-none focus:text-gray;
}

.sub-menu-icon {
    @apply hs-dropdown-open:-rotate-180 duration-300 ms-2 shrink-0 size-4;
}

.hs-dropdown-menu {
    @apply w-full absolute z-10 start-0 min-w-60 bg-white shadow-md rounded-b-lg py-2 px-2 duration-300 ease-in-out;
}

.sub-menu-items-1 {
    @apply w-full left-0;
}

.sub-menu-items-2 {
    @apply flex flex-col;
}

.sub-menu-item {
    @apply flex items-center w-full gap-x-3.5 py-2 px-3 text-left rounded-lg text-sm text-gray-dark hover:bg-gray-light focus:outline-none focus:bg-gray;
}

.menu-item {
    @apply p-2 font-medium focus:outline-none;
}

.menu-side {
    @apply absolute block z-[100] h-20 items-center right-0 overflow-hidden;
}

    .menu-side .open-button {
        @apply block h-full shadow-md bg-white hover:bg-gray-light border border-gray-light rounded-l-lg w-6;
    }

        .menu-side .open-button[aria-expanded="true"] {
            @apply hidden;
        }

    .menu-side .hs-overlay {
        @apply [--auto-close:xl] duration-300 transform fixed top-0 end-0 bottom-0 z-[60] w-64 bg-white border-s border-gray-light pl-2 pt-32 pb-10 overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-light [&::-webkit-scrollbar-thumb]:bg-gray;
    }

    .menu-side .side-menu-pullout {
        @apply rotate-90 size-8 -ml-1 shrink-0;
    }

    .menu-side .close-button {
        @apply rotate-90 size-8 -ml-2 shrink-0;
    }

#menu-side {
    @apply [--body-scroll:true] [--tab-accessibility-limited:false] [--has-autofocus:false];
}

#menu-side-backdrop {
    @apply hidden;
}
