.text-input textarea {
    @apply border block w-full border-input-border text-input-text rounded py-3 px-4 mb-3 leading-tight outline-none focus:border-input-focus-border bg-white;
}

.text-input div.prefix, .text-input div.suffix {
    @apply absolute inline-block;
}

.text-input div.prefix {
    @apply -mx-1 py-1 px-2;
}

.text-input div.suffix {
    @apply -translate-x-20 px-0 py-1;
}

.text-input input {
    @apply appearance-none bg-transparent w-full border-input-border text-input-text py-1 px-2 outline-none border border-x-0 border-y-0 border-b focus:border-input-focus-border;
}

    .text-input input.prefix {
        @apply px-3;
    }

.text-input .view-password {
    @apply absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-300 rounded-e-md focus:outline-none focus:text-layout;
}

    .text-input .view-password > svg {
        @apply shrink-0 size-3.5;
    }
