.file-output .download > a, .file-output .display > div > button {
    @apply py-3 mb-2 mr-2 max-w-full overflow-hidden px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-user-message text-white hover:opacity-80;
}

.file-output .display .hs-overlay {
    @apply size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none content-center;
}

    .file-output .display .hs-overlay > div {
        @apply mt-0 opacity-0 ease-out transition-all max-w-fit m-3 sm:mx-auto h-[calc(100%-5rem)];
    }

    .file-output .display .opened > div {
        @apply opacity-100;
    }

        .file-output .display .hs-overlay > div > div {
            @apply mt-0 max-h-full overflow-hidden flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto;
        }

.file-output .display div.application-pdf, .file-output .display div.application-pdf > div:nth-child(2), .file-output .display div.application-pdf > div:nth-child(2) > div {
    @apply h-full;
}

.file-output .display .hs-overlay > div > div > div:first-child {
    @apply flex justify-between py-3 px-4 border-b;
}

    .file-output .display .hs-overlay > div > div > div:first-child > h3 {
        @apply font-bold text-gray-dark;
    }

    .file-output .display .hs-overlay > div > div > div:first-child > button {
        @apply flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-dark hover:bg-gray-light;
    }

        .file-output .display .hs-overlay > div > div > div:first-child > button > span {
            @apply sr-only;
        }

        .file-output .display .hs-overlay > div > div > div:first-child > button > svg {
            @apply flex-shrink-0 size-4;
        }

.file-output .display .hs-overlay > div > div > div:nth-child(2) {
    @apply p-4 overflow-y-auto;
}

    .file-output .display .hs-overlay > div > div > div:nth-child(2) > div {
        @apply py-4;
    }

        .file-output .display .hs-overlay > div > div > div:nth-child(2) > div > object {
            @apply w-full items-center;
        }

            .file-output .display .hs-overlay > div > div > div:nth-child(2) > div > object > div {
                @apply flex w-full py-3 px-4 border-b;
            }

                .file-output .display .hs-overlay > div > div > div:nth-child(2) > div > object > div > a {
                    @apply w-full;
                }

                    .file-output .display .hs-overlay > div > div > div:nth-child(2) > div > object > div > a > svg {
                        @apply size-6 m-auto;
                    }
