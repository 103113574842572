.tokenex-input input {
    @apply appearance-none bg-transparent w-full text-input-text py-1 px-2 outline-none border border-x-0 border-y-0 border-b focus:border-input-focus-border;
}

.tokenex-cardnumber > div, .tokenex-cvv > div {
    @apply appearance-none relative bg-transparent w-full py-0 px-2 outline-none border border-x-0 border-y-0 border-b;
}

    .tokenex-cardnumber > div.focus, .tokenex-cvv > div.focus {
        @apply border-input-focus-border;
    }

.tokenex-cardnumber.error > div[showerror=yes], .tokenex-cvv.error > div[showerror=yes] {
    @apply border-red-500;
}

.tokenex-cardnumber.error > div:not([showerror=yes]) + p.error, .tokenex-cvv.error > div:not([showerror=yes]) + p.error {
    @apply hidden;
}

.tokenex-cardnumber > div::after {
    @apply absolute content-[''] right-0 top-0 h-full w-9 z-10 bg-default-card-icon bg-contain bg-no-repeat bg-right;
}

.tokenex-cardnumber > div[possiblecardtype=masterCard]::after {
    @apply bg-mastercard-icon;
}

.tokenex-cardnumber > div[possiblecardtype=visa]::after {
    @apply bg-visa-icon;
}

.tokenex-input iframe {
    @apply m-0 p-0 w-full h-9 overflow-hidden;
}
