.select-input {
}
.select-input select {
    @apply hidden;
}

.select-input .toggle {
    @apply relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-input-border rounded-lg text-start text-sm focus:border-input-focus-border focus:ring-input-focus-border before:absolute before:inset-0 before:z-[1];
}


.select-input .dropdown {
    @apply absolute top-full mt-2 z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-input-border rounded-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300;
}

.select-input .option {
    @apply py-2 px-4 w-full text-sm text-gray-dark cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100;
}

.select-input .option-template {
    @apply flex justify-between items-center w-full;
}

    .select-input .option-template svg {
        @apply flex-shrink-0 size-3.5 text-blue;
    }

.select-input .hs-selected\:block {
    @apply hidden;
}

.select-input .selected .hs-selected\:block {
    @apply block;
}

.select-input .select-svg {
    @apply absolute top-1/2 end-3 -translate-y-1/2;
}

    .select-input .select-svg svg {
        @apply flex-shrink-0 size-3.5 text-gray-dark;
    }

.select-input .radio input {
    @apply border-input-border size-4;
}

    .select-input .radio input:checked {
        @apply border-solid border-8 border-input-focus-border;
    }


.select-input .item label {
    @apply cursor-pointer;
}

    .select-input .search {
        @apply block w-full text-sm border-input-border rounded-lg focus:border-input-focus-border focus:ring-input-focus-border before:absolute before:inset-0 before:z-[1] py-2 px-3;
    }

.error-select .toggle {
    @apply !border-red-500;
}