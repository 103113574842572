body, html {
    @apply font-body text-input-text;
}

header > div {
    @apply bg-layout;
}

    header > div::after {
        @apply bg-header content-[''] block h-1 w-full absolute bottom-0 z-10;
    }

#logo {
    @apply w-[170px] h-[56px] p-0;
}

    #logo > img {
        @apply m-2.5 w-[160px];
    }

#main {
    @apply rounded-none;
    background: linear-gradient(190deg, #daf1fb 8.46%, #fff 70%);
}

.menu-side .open-button {
    @apply bg-layout;
}

    .menu-side .open-button .side-menu-pullout {
        @apply text-white;
    }

    .menu-side .open-button:hover {
        @apply bg-layout opacity-75;
    }

#home .message-content {
    @apply rounded-lg shadow-[0_0_27px_0_rgba(0,0,0,.25)];
}

    #home .message-content .submit-button {
        @apply hidden;
    }

#home .message-content {
    @apply text-input-text border-solid border-t-[7px];
}

#home .app-message-content {
    @apply border-app-message-top;
}

#home .user-message-content {
    @apply border-user-message-top;
}

#home .message-edit {
    @apply text-layout duration-[350ms];
}

    #home .message-edit:hover {
        @apply text-white;
    }

#home .app-message-icon {
    @apply bg-layout bg-contain bg-center;
    background-position-x: 2px;
}

.radio-input .button-radio .option label, .cancel-button, .submit-button, .widget-button {
    @apply bg-white rounded-full text-center text-sm text-input-text font-bold uppercase duration-500 min-w-20 max-h-10;
}

.radio-input .button-radio .option label, .cancel-button, .widget-button {
    @apply shadow-[0_0_0_4px_rgba(229,235,237,1)] border-solid border-2 border-gray-dark px-3 mt-6 max-h-fit;
}

    .radio-input .button-radio input:checked + label, .radio-input .button-radio .option:hover label, .cancel-button:hover, .widget-button:hover {
        @apply bg-gray-light;
    }

.widget-button {
    @apply inline-block my-4 cursor-pointer;
}

.submit-button {
    @apply bg-button border-solid border-[6px] border-button-border text-white max-h-12;
}

.submit-button, .cancel-button {
    @apply px-10 min-w-36;
}

a.submit-button {
    @apply table m-auto;
}

.label {
    @apply text-base;
}

.text-input input, .slider-input input[type=number], .tokenex-cardnumber > div, .tokenex-cvv > div {
    @apply border-input-border border-b-[3px];
}

    .text-input input.prefix, .slider-input input[type=number].prefix {
        @apply px-4;
    }

.menu-item, .hs-dropdown-toggle {
    @apply text-input-text duration-[350ms];
}

    .menu-item:hover, .hs-dropdown-toggle:hover {
        @apply opacity-75;
    }

    .hs-dropdown-toggle:focus {
        @apply text-layout;
    }

.widget-output {
    @apply font-bold;
}

.widget-card {
    @apply rounded-lg shadow-[0_0_27px_0_rgba(0,0,0,.25)] text-center py-4 px-2 border-t-[7px];
}

.widget-card-head {
    @apply text-input-text;
}

.widget-card-body {
    @apply text-base;
}

    .widget-card-body > img {
        @apply max-h-16 mx-auto my-4;
    }

    .widget-card-body > div {
        @apply px-2 text-wrap;
    }

        .widget-card-body > div > details {
            @apply text-left;
        }

            .widget-card-body > div > details > summary:hover {
                @apply cursor-pointer italic;
            }

            .widget-card-body > div > details > ul {
                @apply text-xs list-[circle] list-outside ml-4 font-normal;
            }

        .widget-card-body > div > div {
            @apply text-2xl md:text-xl lg:text-3xl;
        }

            .widget-card-body > div > div > div {
                @apply text-base;
            }

.widget-button.selected, .widget-card.active span {
    @apply hidden;
}

.widget-card.active .selected, .widget-card.active .selected:hover {
    @apply block border-none shadow-none cursor-default bg-transparent;
}

:first-child.widget-card {
    @apply border-yellow;
}

:nth-child(2).widget-card {
    @apply border-purple;
}

:nth-child(3).widget-card {
    @apply border-button;
}

:nth-child(4).widget-card {
    @apply border-green;
}
