#home .message-bubble {
    @apply flex items-start gap-1 m-0;
}

    #home .message-bubble > div {
        @apply relative w-full p-2 border-gray-light rounded-e-xl rounded-es-xl;
    }

#home .user-message-icon, .app-message-icon {
    @apply w-10 h-10 absolute border border-solid rounded-full top-6 bg-icon bg-no-repeat bg-cover;
}

#home .user-message-icon {
    @apply left-2 bg-user-icon;
}

#home .app-message-icon {
    @apply right-2 bg-app-icon;
}

#home .message-content {
    @apply flex flex-wrap mb-4 p-4 shadow-md rounded-2xl text-white;
}

    #home .message-content .message .title {
        @apply text-base font-bold;
    }

    #home .message-content .message .content {
        @apply text-base font-normal break-words;
    }

        #home .message-content .message .content table {
            @apply whitespace-nowrap max-w-full text-xs;
        }

#home .message-date {
    @apply w-full flex h-6;
}

#home .message-name {
    @apply w-full flex text-xs font-normal;
}

    #home .message-date span {
        @apply text-xs font-normal;
    }

#home .message-edit {
    @apply flex justify-end p-1.5 rounded-xl hover:rounded-3xl hover:bg-button-hover transition-all duration-200 text-white disabled:opacity-50 disabled:pointer-events-none;
}

    #home .message-edit > svg {
        @apply h-6 w-6;
    }

#home .user-message-content {
    @apply ml-12 bg-user-message;
}

    #home .user-message-content .message-date {
        @apply justify-between items-center;
    }

    #home .user-message-content .message {
        @apply w-full flex flex-wrap flex-col mt-2 overflow-x-hidden;
    }

        #home .user-message-content .message .title {
            @apply text-base font-bold;
        }

        #home .user-message-content .message .content {
            @apply text-base font-normal break-words;
        }

#home .app-message-content {
    @apply mr-12 bg-app-message items-center;
}

    #home .app-message-content .message-date {
        @apply justify-end;
    }





#nochat-home .message-bubble {
    @apply flex items-center gap-1 m-0;
}

    #nochat-home .message-bubble > div {
        @apply relative p-1;
    }

    #nochat-home .message-bubble > svg {
        @apply -ml-2 shrink-0 size-4 text-white;
    }

#nochat-home .user-message-icon, #nochat-home .app-message-icon {
    @apply hidden;
}

#nochat-home .message-content {
    @apply flex text-nowrap text-white text-xs;
}

#nochat-home .user-message-content .content {
    @apply hidden;
}

#nochat-home .message-date, #nochat-home .message-name {
    @apply flex flex-row items-center;
}

#nochat-home .message-edit {
    @apply flex justify-end ml-1 p-2 rounded-full hover:bg-button-hover transition-all duration-200 text-white disabled:opacity-50 disabled:pointer-events-none;
}

        #nochat-home .message-edit > svg {
            @apply h-3 w-3;
        }

#nochat-home .app-message-content {
    @apply mr-1 items-center;
}
