.slider-input div.prefix, .slider-input div.suffix {
    @apply absolute inline-block;
}

.slider-input div.prefix {
    @apply -mx-1 py-1 px-2;
}

.slider-input div.suffix {
    @apply -translate-x-20 px-0 py-1;
}

.slider-input input[type=number] {
    @apply appearance-none bg-transparent w-full text-input-text py-1 px-2 outline-none border border-x-0 border-y-0 border-b focus:border-input-focus-border;
}

.slider-input input.prefix {
    @apply px-3;
}

.slider-input input[type=range] {
    @apply w-full h-2.5 mt-4 outline-none appearance-none rounded-full border-solid border border-input-text shadow-md cursor-pointer [&::-webkit-slider-thumb]:size-4 [&::-webkit-slider-thumb]:-mt-1 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:bg-white [&::-webkit-slider-thumb]:border-solid [&::-webkit-slider-thumb]:border-2 [&::-webkit-slider-thumb]:border-input-text [&::-webkit-slider-thumb]:shadow-md [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:transition-all [&::-webkit-slider-thumb]:duration-150 [&::-webkit-slider-thumb]:ease-in-out [&::-webkit-slider-runnable-track]:w-full [&::-webkit-slider-runnable-track]:h-2 [&::-webkit-slider-runnable-track]:bg-gray-light [&::-webkit-slider-runnable-track]:rounded-full focus:border-input-focus-border;
}
