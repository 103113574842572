.submit {
    @apply mx-auto flex place-content-center;
}

.submit-button, .cancel-button {
    @apply bg-button hover:bg-button-hover text-white py-2 px-10 rounded-md mt-5 mx-2 disabled:opacity-50;
}

.cancel-button {
    @apply ml-3;
}